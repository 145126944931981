import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&amp;display=swap"
        rel="stylesheet"
      />
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-web" role="menuitem">
                <Link activeClassName="active" to={`/web-apps`}>
                  Web Apps
                </Link>
              </li>
              <li className="nav-web" role="menuitem">
                <Link activeClassName="active" to={`/websites`}>
                  Sites
                </Link>
              </li>
              <li className="nav-ecomm" role="menuitem">
                <Link activeClassName="active" to={`/ecommerce`}>
                  Ecomm
                </Link>
              </li>
              <li className="nav-ecomm" role="menuitem">
                <Link activeClassName="active" to={`/user-flows`}>
                  User Flows
                </Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <ul className="nav" role="menu">
              <li className="nav-data" role="menuitem">
                <Link activeClassName="active" to={`/data-viz`}>
                  Data Viz
                </Link>
              </li>
              <li className="nav-art" role="menuitem">
                <Link activeClassName="active" to={`/art`}>
                  Art
                </Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link activeClassName="active" to={`/about`}>
                  About &amp; Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div>
  )
}

export default Layout
